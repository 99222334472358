/*
  Design System Styles
*/
@import '@dpc-odg/libs-web-design-system/dist/styles.css';

/*
  Banner 
*/
#banner {
  width: 100%;
  background-color: #004b88; // Keep this always the same blue, only temporary anyway.
  padding: var(--sagov-spacer-1);
  display: flex;
  padding-left: 100px;
  gap: 50px;
  align-items: center;

  @media screen and (max-width: 768px) {
    gap: 0;
    padding-left: 0px;
    flex-wrap: wrap;
    justify-content: center;
    span {
      text-align: center;
    }
  }

  span,
  span a {
    color: var(--sagov-white-color);
  }

  .banner-bold-text {
    font-weight: var(--sagov-font-weight-bold);
    font-size: var(--sagov-h3-font-size);
  }

  @media screen and (max-width: 768px) {
    gap: 0;
    padding-left: 0px;
    flex-wrap: wrap;
    justify-content: center;
    span {
      text-align: center;
      font-weight: var(--sagov-font-weight-bold);
      font-size: var(--sagov-small-font-size);
    }
    .banner-bold-text {
      width: 100%;
      font-size: var(--sagov-small-font-size);
    }
  }
}

/* 
  Layout
*/
html,
body,
#app,
section {
  height: 100%;
}

.app-container {
  flex: 1;
  position: relative;
}

.dashboard {
  @media screen and (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  }
}

.app-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.main-body {
  display: flex;
  width: 100%;

  .sidebar {
    flex: 1;
    background-color: var(--sagov-grey-color);
    color: var(--sagov-text-color);
    border-right: 1px solid var(--sagov-black-light-05);
    width: 250px;
    min-width: 250px;
    max-width: 15%;

    @media (prefers-color-scheme: dark) {
      background-color: #000;
      border-right: 1px solid #575757;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .dashboard {
    flex: 5;
    min-width: 70%;

    padding: 0;
    padding-top: 80px;
    margin: 0;
    max-width: 85%;
    padding-left: var(--sagov-spacer-6);
    padding-right: var(--sagov-spacer-6);
    padding-bottom: var(--sagov-spacer-6);

    @media screen and (max-width: 650px) {
      padding-top: var(--sagov-spacer-5);
      padding-left: var(--sagov-spacer-2);
      padding-right: var(--sagov-spacer-2);
    }
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
}

.main-dashboard-container {
  display: flex;
  flex-direction: column;
  gap: var(--sagov-spacer-4);
  .full-width {
    width: 100%;
  }
  .columns {
    display: flex;
    // flex-wrap:wrap;
    gap: var(--sagov-spacer-4);
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }

  .left-side {
    width: 60%;
    @media screen and (max-width: 1200px) {
      min-width: 100%;
    }
  }

  .right-side {
    min-width: 20%;
    flex: 1;
  }
  .left-side,
  .right-side {
    display: flex;
    flex-direction: column;
    gap: var(--sagov-spacer-4);
  }
}

/*
  Menu
*/
.menu-item-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-top: var(--sagov-spacer-3);

  .menu-item {
    text-align: center;
    font-weight: var(--sagov-font-weight);

    &:hover,
    &.active,
    &:has(.active) {
      background-color: var(--sagov-hover-blue);
      cursor: pointer;
    }

    a {
      text-decoration: none;
      display: flex;
      width: 100%;
      justify-content: left;
      padding: 12px 0;
      padding-left: var(--sagov-spacer-2);
    }
  }
}

.logout-link {
  font-weight: var(--sagov-font-weight-bold);
  font-size: var(--sagov-h4-font-size);
  color: var(--sagov-blue-color);
  text-decoration: none;
  margin-left: var(--sagov-spacer-2);

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.hamburger-menu .logout-link {
  padding: 12px 0;
  padding-left: var(--sagov-spacer-2);
  width: 100%;
  display: block;
}

.hamburger-menu {
  position: absolute;
  left: 0;
  top: 165px;
  width: 100%;
  z-index: 999;
  background-color: var(--sagov-background-color);
}

/* styles for the top menus */
span[slot='left-menu'] {
  display: none;

  @media screen and (max-width: 768px) {
    display: inline-block;
  }
}

div[slot='right-menu'] {
  display: flex;
  justify-content: space-between;
}

#loading-spinner {
  display: none;
}
